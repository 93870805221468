<template>
  <div class="flex flex-col rounded-lg bg-brick-100 p-3 lg:flex-row lg:p-12">
    <div class="flex flex-col justify-center lg:w-1/3 lg:pr-3">
      <template v-if="isLinkExpired">
        <h2>Der Bestätigungslink ist abgelaufen.</h2>
        <p class="mt-6">
          Keine Sorge: Gib einfach deine Daten noch einmal ein und wir schicken dir einen neuen Link
          zu.
        </p>
      </template>
      <template v-else>
        <h2>Vervollständige dein Konto</h2>
        <p class="mt-6">
          Aktiviere jetzt dein Konto, um alle Vorteile von haelsi zu nutzen und z. B. deine
          Dokumente einzusehen.
        </p>
      </template>
    </div>
    <div class="mt-4 lg:mt-0 lg:w-2/3">
      <form
        v-if="loading === false"
        class="rounded-lg bg-white px-6 py-4"
        @submit.prevent="handleProfileConnection"
      >
        <AppAlert v-if="errorMessage" type="error" class="mb-2">{{ errorMessage }}</AppAlert>
        <div class="sm:flex sm:flex-wrap">
          <InputField
            v-model="firstName"
            class="sm:w-1/2 sm:pr-2"
            input-id="firstName"
            :is-required="true"
            label="Vorname"
            type="text"
            autocomplete="given-name"
          />
          <InputField
            v-model="lastName"
            class="sm:w-1/2 sm:pl-2"
            input-id="lastName"
            :is-required="true"
            label="Nachname"
            type="text"
            autocomplete="family-name"
          />
          <InputBirthdate
            v-model="birthdate"
            class="text-left sm:w-1/2 sm:pr-2"
            label="Geburtsdatum"
            :is-required="true"
            field-name="birthdate"
          />
          <InputSocialSecurityNumber
            v-model="socialSecurityNumber"
            field-name="socialSecurityNumber"
            label="Sozialversicherungsnummer"
            class="sm:w-1/2 sm:pl-2"
            :is-required="true"
          />
        </div>
        <AppButton variant="secondary" class="mt-2 xl:w-1/2" type="submit">
          <template v-if="isLinkExpired">Neuen Link anfordern</template>
          <template v-else>Konto aktivieren</template>
          <SvgIcon name="chevronLeft" class="rotate-180" aria-hidden="true" />
        </AppButton>
      </form>
      <ProfileConnectionLoading v-if="loading" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { markRaw, ref } from 'vue';
import { ObjectSchema, object, string } from 'yup';

import AppAlert from '@/components/AppAlert.vue';
import AppButton from '@/components/AppButton.vue';
import InputBirthdate from '@/components/InputBirthdate.vue';
import InputField from '@/components/InputField.vue';
import InputSocialSecurityNumber from '@/components/InputSocialSecurityNumber.vue';
import ProfileConnectionLoading from '@/components/ProfileConnectionLoading.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import { GeneralErrors } from '@/constants/errors/general.error';
import { InputErrors } from '@/constants/errors/input.error';
import { userService } from '@/services';
import { useUserStore } from '@/stores/user.store';

interface MedicalConnection {
  firstName: string;
  lastName: string;
  birthdate: string;
  socialSecurityNumber: string;
}

const props = withDefaults(
  defineProps<{
    userId: string;
    isLinkExpired?: boolean;
  }>(),
  {
    isLinkExpired: false,
  },
);
const userStore = useUserStore();
const { user } = userStore;

const initialValues: MedicalConnection = {
  firstName: user?.firstName ?? '',
  lastName: user?.lastName ?? '',
  birthdate: user?.birthdate ?? '',
  socialSecurityNumber: user?.socialSecurityNumber ?? '',
};

const schema: ObjectSchema<MedicalConnection> = markRaw(
  object({
    firstName: string().required(InputErrors.required.firstname),
    lastName: string().required(InputErrors.required.lastname),
    birthdate: string()
      .required(InputErrors.required.birthdate)
      .matches(
        new RegExp('\\b(0[1-9]|[1-3][0-9]).(0[1-9]|1[0-2]).([1-2][0,9][0-9][0-9])\\b'),
        InputErrors.incorrect.birthdate.format,
      )
      .length(10, InputErrors.incorrect.birthdate.default),
    socialSecurityNumber: string()
      .required(InputErrors.required.socialSecurityNumber)
      .min(4, InputErrors.incorrect.socialSecurityNumber)
      .max(11, InputErrors.incorrect.socialSecurityNumber),
  }),
);

const { handleSubmit } = useForm({
  validationSchema: schema,
  initialValues,
});

const { value: firstName } = useField<string>('firstName');
const { value: lastName } = useField<string>('lastName');
const { value: birthdate } = useField<string>('birthdate');
const { value: socialSecurityNumber } = useField<string>('socialSecurityNumber');
const errorMessage = ref<string | undefined>();
const loading = ref(false);

function handleProfileConnection(): void {
  errorMessage.value = '';

  handleSubmit((params) => {
    loading.value = true;
    userService
      .update(
        props.userId,
        params.firstName,
        params.lastName,
        params.birthdate,
        params.socialSecurityNumber,
      )
      .then(({ error, data: user }) => {
        if (error) {
          errorMessage.value = GeneralErrors.default;

          return;
        }

        userStore.$patch({ user: user.data });
        loading.value = false;
      });
  })();
}
</script>

<style scoped></style>
