import { h } from 'vue';
import { RouterView, createRouter, createWebHistory } from 'vue-router';

import authGuard from '@/guards/auth.beforeEnter.guard';
import guestGuard from '@/guards/guest.beforeEnter.guard';
import membershipGuard from '@/guards/membership.beforeEnter.guard';
import verificationTokenGuard from '@/guards/verificationToken.beforeEnter.guard';
import IndexPage from '@/pages/IndexPage.vue';
import { addTitleFunctionality } from '@/router/title';
import { Layout } from '@/types/Layout';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      beforeEnter: [authGuard],
      meta: {
        layout: Layout.Sidebar,
      },
      children: [
        {
          path: '/home',
          name: 'home',
          beforeEnter: [verificationTokenGuard, authGuard],
          component: IndexPage,
          meta: {
            title: 'Start',
          },
        },
        {
          path: '/special-discount',
          beforeEnter: [membershipGuard],
          name: 'special-discount',
          component: () => import('@/pages/SpecialDiscountPage.vue'),
          meta: {
            title: 'Partnerrabatt',
          },
        },
        {
          path: '/documents',
          name: 'documents',
          beforeEnter: [authGuard],
          component: () => import('@/pages/DocumentsPage.vue'),
          meta: {
            title: 'Dokumente',
          },
          redirect: { name: 'prescriptions' },
          children: [
            {
              path: 'prescriptions',
              name: 'prescriptions',
              beforeEnter: [authGuard],
              component: () => import('@/pages/PrescriptionsPage.vue'),
              meta: {
                title: 'Rezepte',
              },
            },
            {
              path: 'referrals',
              name: 'referrals',
              beforeEnter: [authGuard],
              component: () => import('@/pages/ReferralsPage.vue'),
              meta: {
                title: 'Überweisungen',
              },
            },
            {
              path: 'doctor-notes',
              name: 'doctorNotes',
              beforeEnter: [authGuard],
              component: () => import('@/pages/DoctorNotesPage.vue'),
              meta: {
                title: 'Arztbriefe',
              },
            },
            {
              path: 'others',
              name: 'otherDocuments',
              beforeEnter: [authGuard],
              component: () => import('@/pages/OtherDocumentsPage.vue'),
              meta: {
                title: 'Sonstige Dokumente',
              },
            },
            {
              path: 'upload',
              name: 'uploadDocuments',
              beforeEnter: [authGuard],
              component: () => import('@/pages/UploadDocumentsPage.vue'),
              meta: {
                title: 'Upload',
              },
            },
          ],
        },
        {
          path: '/profile',
          name: 'profile',
          beforeEnter: [authGuard],
          redirect: { name: 'membership' },
          children: [
            {
              path: 'membership',
              meta: {
                title: 'Mitgliedschaft & Abrechnung',
              },
              children: [
                {
                  path: '',
                  name: 'membership',
                  beforeEnter: [authGuard],
                  component: () => import('@/pages/MembershipPage.vue'),
                },
              ],
            },
          ],
        },
        {
          path: '/appointments',
          name: 'appointments',
          beforeEnter: [authGuard],
          component: () => import('@/pages/AppointmentsPage.vue'),
          meta: {
            title: 'Termine',
          },
          redirect: { name: 'upcomingAppointments' },
          children: [
            {
              path: 'upcoming',
              component: { render: () => h(RouterView) },
              children: [
                {
                  path: '',
                  name: 'upcomingAppointments',
                  beforeEnter: [authGuard],
                  component: () => import('@/pages/UpcomingAppointmentsPage.vue'),
                  meta: {
                    title: 'Anstehende Termine',
                  },
                },
                {
                  path: ':id',
                  name: 'appointment',
                  beforeEnter: [authGuard],
                  component: () => import('@/pages/AppointmentPage.vue'),
                  meta: {
                    title: 'Termin',
                  },
                },
              ],
            },
            {
              path: 'past',
              name: 'pastAppointments',
              beforeEnter: [authGuard],
              component: () => import('@/pages/PastAppointmentsPage.vue'),
              meta: {
                title: 'Vergangene Termine',
              },
            },
          ],
        },
        {
          path: '/invoices',
          name: 'invoices',
          beforeEnter: [authGuard],
          component: () => import('@/pages/InvoiceListPage.vue'),
          meta: {
            title: 'Rechnungen',
          },
        },
        {
          path: '/invoices/:id',
          name: 'invoice',
          beforeEnter: [authGuard],
          component: () => import('@/pages/InvoiceDetailPage.vue'),
          meta: {
            title: 'Rechnung',
          },
        },
        {
          path: '/messages',
          name: 'messages',
          beforeEnter: [authGuard],
          component: () => import('@/pages/MessagesPage.vue'),
          meta: {
            title: 'Nachrichten',
          },
        },
      ],
    },
    {
      path: '/',
      meta: {
        layout: Layout.FullPage,
      },
      children: [
        {
          path: '/login',
          name: 'login',
          beforeEnter: [verificationTokenGuard, guestGuard],
          components: {
            default: () => import('@/pages/LoginPage.vue'),
            rightSidebar: () => import('@/layouts/sidebars/BenefitSidebar.vue'),
            footer: () => import('@/layouts/footers/FooterRedirectRegister.vue'),
          },
          meta: {
            title: 'Einloggen',
          },
        },
        {
          path: '/register',
          name: 'register',
          beforeEnter: [verificationTokenGuard, guestGuard],
          components: {
            default: () => import('@/pages/RegisterPage.vue'),
            rightSidebar: () => import('@/layouts/sidebars/BenefitSidebar.vue'),
            footer: () => import('@/layouts/footers/FooterRedirectLogin.vue'),
          },
          meta: {
            title: 'Registrierung',
          },
        },
      ],
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      components: {
        default: () => import('@/pages/ForgotPasswordPages/ResetPasswordPage.vue'),
        rightSidebar: () => import('@/layouts/sidebars/BenefitSidebar.vue'),
      },
      meta: {
        title: 'Neues Passwort setzen',
        layout: Layout.FullPage,
      },
    },
    {
      path: '/email-confirmation',
      name: 'email-confirmation',
      components: {
        default: () => import('@/pages/EmailConfirmationPage.vue'),
        rightSidebar: () => import('@/layouts/sidebars/EmailIllustrationSidebar.vue'),
        footer: () => import('@/layouts/footers/FooterRedirectLogin.vue'),
      },
      meta: {
        layout: Layout.FullPage,
        title: 'E-mail Bestätigung',
        hasNavigationBack: true,
      },
    },
    {
      path: '/link-expired',
      name: 'link-expired',
      components: {
        default: () => import('@/pages/LinkExpiredPage.vue'),
        rightSidebar: () => import('@/layouts/sidebars/PersonIllustrationSidebar.vue'),
        footer: () => import('@/layouts/footers/FooterRedirectLogin.vue'),
      },
      meta: {
        layout: Layout.FullPage,
        title: 'Link abgelaufen',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      components: {
        default: () => import('@/pages/ForgotPasswordPages/ForgotPasswordPage.vue'),
        rightSidebar: () => import('@/layouts/sidebars/BenefitSidebar.vue'),
      },
      meta: {
        layout: Layout.FullPage,
        title: 'Passwort vergessen',
        hasNavigationBack: true,
      },
    },
    {
      path: '/reset-password-success',
      name: 'reset-password-success',
      components: {
        default: () => import('@/pages/ForgotPasswordPages/ResetPasswordSuccessPage.vue'),
        rightSidebar: () => import('@/layouts/sidebars/BenefitSidebar.vue'),
      },
      meta: {
        layout: Layout.FullPage,
        title: 'Passwort erfolgreich geändert',
      },
    },
    { path: '/:pathMatch(.*)*', redirect: { name: 'home' } },
  ],
});

addTitleFunctionality(router);

export default router;
