import { BaseService } from '@/services/base.service';
import type { File as CustomFile } from '@/types/api/File.model';
import type { FileType } from '@/types/api/FileType.enum';
import type { Page } from '@/types/api/Page.model';
import type { HttpClientResponse } from '@/types/HttpClientResponse';

// To disable pagination for now we are setting a high number for items per page
const FILE_REQUEST_LIMIT = 1000;

export class FileService extends BaseService {
  async getFiles(
    type: FileType | null,
    pageRequestLink?: string,
    pageSize: number = FILE_REQUEST_LIMIT,
  ): Promise<HttpClientResponse<Page<CustomFile>>> {
    const requestLink = pageRequestLink ? pageRequestLink : '/api/files/search';

    return this.post<Page<CustomFile>>(requestLink, {
      filters: [
        {
          field: 'type',
          operator: '=',
          value: type,
        },
      ],
      sort: [
        {
          field: 'date',
          direction: 'desc',
        },
      ],
      limit: pageSize,
    });
  }

  async postFiles(files: File[]): Promise<HttpClientResponse<Page<File>>> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files[]', file);
    }

    return this.post<Page<File>>('/api/file/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
